import i18n from "i18next";
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { config } from "../_config";
import * as moment from 'moment';
import { history } from "../_helpers";
import i18next from "i18next";
import Heading from "../containers/Heading/Heading";
import offerbg from "../_assets/images/icons/reward-bg.png"
import star from "../_assets/images/icons/star.png"
import bottle from "../_assets/images/icons/bottle.png"
import FooterNav from "../components/FooterNav/FooterNav";
import DiscountOffer from "../components/DiscountOffer/DiscountOffer";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from "../_helpers/encryptData";
import { getDecouplePromotion } from "../_services/reward.service";
import DecouplePromo from "../components/DecouplePromo/DecouplePromo";
import scan from "../_assets/images/icons/scan.png";
import RewardPoints from "../components/RewardPoints/RewardPoints";
import cokebanner from "../_assets/images/splashscreen/cokebanner.svg"
import rewardbg from "../_assets/images/splashscreen/reward-bg.png"
import got from "../_assets/images/icons/gotit.png";
export default class Offer extends Component {
    constructor(props){
        super(props);
        document.title=`Coke & Go ${config.countryName} | offer`;
        CapturePage({hitType:"pageview",page:"/offer"})
        this.state = {
            BuyQty:0,
            PurchaseQty:0,
            isLoading:true,
            showPopup:false,
            errorCode:0,
            errorMsg:'',
            isOffline:false,
            promotionOffers:[],
            isPromoLoading:true,
            purchasedBrand: []
        }
        // let rewardData = JSON.parse(localStorage.getItem('reward'));
        let qrcode = decryptData(localStorage.getItem('qrcode'));
        if(!qrcode){
          history.push('/ScanButton');
        }
        let user = JSON.parse(decryptData(localStorage.getItem('user')));
        let authToken= user.authToken;
        let macAddress= qrcode
        let applicationTypeId= config.applicationTypeId;
        let RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({authToken,applicationTypeId,macAddress})
        }
        let that = this;
        fetch(`${config.envUrl}VendingAppApi/getPurchaseOfferQty`, RequestOptions)
        .then(function (response) {
            return response.json();
        })
        .then((result) => {
            if(result.success){
                that.setState({
                    isLoading:false,
                    BuyQty:result.records[0].BuyQty,
                    PurchaseQty:result.records[0].PurchaseQty,
                    purchasedBrand: result.purchasedBrand
                })
            }
            else{
                that.setState({
                    showPopup:true,
                    isLoading:false,
                    errorCode:result.code,
                    errorMsg:result.message
                })
            }
        }).catch(function (error) {
            that.setState({
                isLoading:false,
                isOffline:true
            })
        });

        this.closingPopup = this.closingPopup.bind(this);
        this.closingisOfflinePopup = this.closingisOfflinePopup.bind(this);
        if(qrcode){
            this.getPromoDetails();
        }
    }
    getPromoDetails=async()=>{
        let that =this
        try {
          getDecouplePromotion("reward").then((data)=>{
            if(data.success && data.code==5014){
            let homePD = data.data.reward.sort((a,b)=>a.bannerPositionInScreen-b.bannerPositionInScreen)
            that.setState(prevstate=>({
              ...prevstate,
              promotionOffers:homePD,
              isPromoLoading:false
            }))
            }
          })
          .catch((err)=>{
            that.setState({
                isPromoLoading:false
            })
          })
        } catch (error) {
          console.log(error)
          that.setState({
            isPromoLoading:false
        })
        }
    }
    closingPopup(){
        if(this.state.errorCode==401){
            history.push('/Logout')
        }
        else{
            this.setState({
                showPopup:false,
                errorCode:0
            })
        }
    }
    closingisOfflinePopup(){
        this.setState({
          isOffline:false
        })
    }
    openScanner=()=> {
        history.push({
            pathname: "/Scanner"
        });
    }
    nth = (d) => {
        // console.log(d)
        if (d > 3 && d < 21) return `${d}th`;
        switch (d % 10) {
          case 1:  return `${d}st`;
          case 2:  return `${d}nd`;
          case 3:  return `${d}rd`;
          default: return `${d}th`;
        }
      };
    render() {
        const style = {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          };
        let rewardData = JSON.parse(decryptData(localStorage.getItem('isRewardAvailable')));
        let reward = JSON.parse(decryptData(localStorage.getItem('reward')));
        var {separateOfferCoupon:separateOfferCoupon=[], OfferCoupon: OfferCoupon=[], isFBFRedeemedOnSameCard:isFBFRedeemedOnSameCard=false} = reward;
        let count = [1,2,3,4,5,6,7,8,9,10]
        const {promotionOffers} = this.state
        let userMail = localStorage.getItem("username")
        let mainMail;
        let FBFMail;
        if(userMail){
        mainMail = decryptData(localStorage.getItem("username"))
        }
        let FBFSameCardMail = localStorage.getItem("FBFRedeemOnSameCard")
        if(FBFSameCardMail){
        FBFMail = decryptData(localStorage.getItem("FBFRedeemOnSameCard"))
        }
        let match = true;
        if(FBFSameCardMail==undefined){
        match = true
        }
        else if(mainMail==FBFMail){
        match = false
        }
        // console.log(reward)
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return (
            <div className="main-wrapper">
                <div className="fix-header">
                    <div className="scan-btn my-1 d-flex " onClick={this.openScanner}>
                        <img src={scan} height={30} /> 
                        <h5 className="mt-0">{i18next.t('ScanCoolerQR')}</h5> 
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="content p-0">                                  
                                
                                    <div className="main-content">
                                    { (rewardData.length>0) &&
                                        <div className="rewards-list">
                                        <h4 className="title">{i18next.t("Your rewards")}</h4>
                                        { isFBFRedeemedOnSameCard && match && 
                                            <div className="FBFhomereward">
                                                <div className="FBFText">
                                                    <label className="m-0">{i18next.t("FBFRedeemedOnSameCardHomeRewardScreen")}&nbsp;<br/>
                                                    <Link className="updatepayment" to={'/Card'}>{i18next.t("Update payment option")}</Link></label>
                                                </div>
                                                <div className="ml-2 icon-text-wrapper">
                                                    <img onClick={()=>{localStorage.setItem("FBFRedeemOnSameCard",userMail);window.location.reload()}} src={got} alt="icon"/>
                                                    <label onClick={()=>{localStorage.setItem("FBFRedeemOnSameCard",userMail);window.location.reload()}} className="gotit-text">{i18next.t("Got It!")}</label>
                                                </div>
                                            </div>
                                        }
                                        { separateOfferCoupon.length>0 &&
                                        <div className="reward-banner mb-4">
                                            <div className="row">
                                                <div className="col-7 pl-4">
                                                    {config.applicationTypeId==20 ? <h5>{i18next.t("Free Drink is applicable per user+card option and not per user registration basis")}</h5> : <h5>{i18next.t("CHEERS! Enjoy your first beverage on us.")}</h5>}
                                                </div>
                                                <div className="col-5">
                                                    <img src={cokebanner} width={'100%'}/>
                                                </div>
                                                </div>
                                                <div className="row px-3">
                                                <div className="col-7">
                                                    <p className="redeem-now" onClick={this.openScanner}>{i18next.t('Redeem Now')}</p>
                                                </div>
                                                <div className="col-5 text-right">
                                                    <Link to={config.termsOfUseAndSale}><p>{i18next.t('Terms')}</p></Link>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <RewardPoints />
                                        </div>
                                        }
                                        {
                                            (config.applicationTypeId==20 && reward && reward.success) && 
                                            (
                                                reward.OfferCoupon.map((item,key)=>{
                                                    let date = new Date(item.ExpiryDate);
                                                    return <>
                                                        <h5>Claimed Rewards</h5>
                                                        <div className="reward-expiry" style={{background:`url(${rewardbg})`}}>
                                                            <h4>Buy 10 Get 1 Free</h4>
                                                            <h5>Expires on <b>{this.nth(date.getDate())} {monthNames[date.getMonth()]}</b></h5>
                                                            <p>Redeem your free drink by scanning the QR code on the Coke&Go nearby cooler</p>
                                                        </div>
                                                    </>
                                                })
                                            )
                                        }
                                    {
                                        (reward && reward.success) && 
                                        <>
                                        {
                                            reward.rewardPointOffers.map((offer,key)=>{
                                            return <DiscountOffer key={key} offer={offer} offertype="rewardPointOffers" />
                                            })
                                        }
                                        </>
                                    }
                                    {
                                        (reward && reward.success) && 
                                        <>
                                        {
                                            reward.promotionalOffer.map((offer,key)=>{
                                            return <DiscountOffer key={key} offer={offer} offertype="promotionalOffer" />
                                            })
                                        }
                                        </>
                                    }
                                    {
                                        promotionOffers.length>0 && 
                                        <div className="mt-3">
                                        {
                                            promotionOffers.map((item)=>{
                                                return <DecouplePromo {...item}  backLink="/offer"/>
                                                
                                            })
                                        }
                                        </div>
                                    }
                                    <FooterNav />
                                    
                                </div> 
                                {
                                    (this.state.isLoading && this.state.isPromoLoading) &&
                                    <div className="spinner">
                                        <div style={style}>
                                        <ClipLoader size={50} color={"#000000"} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
