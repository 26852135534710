import i18next from 'i18next';
import React, { Component } from 'react'
import { ClipLoader } from 'react-spinners';
import Button from '../components/button';
import CoolerPopup from '../components/Model-Popup/Popup';
import Heading from '../containers/Heading/Heading';
import { config } from '../_config';
import left_angle from "../_assets/images/icons/back-with-black-bg.svg"
import { Link } from 'react-router-dom';
import { history } from '../_helpers';
import IssueOptions from "../_helpers/IssueDropDown"
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import moment from "moment";
import { decryptData, validateString } from '../_helpers/encryptData';
import { utilService } from "../_services/util.service";
import validator from 'validator';
export default class NeedHelp extends Component {
    constructor(props){
        super(props);
        document.title=`Coke & Go ${config.countryName} | NeedHelp`;
        CapturePage({hitType:"pageview",page:"/NeedHelp/:orderId"}) 
        const user = JSON.parse(decryptData(localStorage.getItem('user')));
        const {primaryEmail}=user;

        this.state = {
            showFromEmail:primaryEmail.indexOf("privaterelay")>-1?true:false,
            isBtnDisable:primaryEmail.indexOf("privaterelay")>-1?true:false,
            isInvalidEmail:false,
            isLoading:false,
            purchaseNo:props.match.params.orderId?props.match.params.orderId:"",
            ShowPopUp:false,
            description:'',
            issue:'',
            popupsmg:'',
            fromEmail:'',
            imageType:'error'
        }
        this.closingShowPopUp = this.closingShowPopUp.bind(this)
        this.handleTextChange = this.handleTextChange.bind(this)
        this.sendMail = this.sendMail.bind(this);
        let isMailSent = sessionStorage.getItem('isMailSent');
        if(isMailSent=='true' && this.props.location.state.purchaseDate){
            history.push('/ScanButton')
        }
        this.sendMetricsTracking();
    }

    sendMetricsTracking = () => {
        utilService.metricsTracking("NeedHelpPage");
    }

    closingShowPopUp(){
        if(this.state.imageType=='success'){
            history.push('/ScanButton')
        }
        else{
            this.setState({
                ShowPopUp:false
            })
        }
    }
    
    handleTextChange(e){
        let testString=true
        let {name, value} = e.target
        if(name=='description'){
            testString=validateString(value,'description')
        }
        else if(name=='fromEmail'){
            this.setState({
                isInvalidEmail:!validator.isEmail(value),
                isBtnDisable:!validator.isEmail(value),
                fromEmail:value
            })
        }
        if(testString){
            this.setState({
                [name]:value
            })
        }
    }

    sendMail(e){
        e.preventDefault()
        let supportDuration = this.props.location.state.SupportButtonDuration;
        let transactionDate = this.props.location.state.purchaseDate;

        transactionDate = moment(transactionDate);
        let currentDate = moment();
        let difference = currentDate.diff(transactionDate,'hours');
        if(difference > supportDuration && supportDuration != 0){
            this.setState({
                ShowPopUp:true,
                popupsmg:i18next.t("Support duration is expired for this transaction"),
                imageType:'error'
            })  
            return;
        }
        let that = this;
        that.setState({isLoading:true})
        let user = JSON.parse(decryptData(localStorage.getItem('user')));
        const {isLatamCountry,manualCorrectionMail,supportEmail} = config;
        // let toEmail = "santosh.kumar@maxerience.com,krsantosh97627@gmail.com";
        let toEmail = isLatamCountry?`${manualCorrectionMail},${supportEmail}`:manualCorrectionMail;
        let authToken = user.authToken
        let applicationTypeId = config.applicationTypeId
        let purchaseNumber = this.state.purchaseNo
        let message = this.state.description
        let issue = this.state.issue
        let fromEmail=this.state.fromEmail
        let languageCode = localStorage.selectedLanguage ? localStorage.selectedLanguage : config.defaultLanguage
        let sessionUId = sessionStorage.getItem('sessionId');
        if(issue==""){
            that.setState({
                isLoading:false,
                ShowPopUp:true,
                popupsmg:i18next.t("Please select Issue type"),
                imageType:'error'
            })  
        }
        else if(message==""){
            that.setState({
                isLoading:false,
                ShowPopUp:true,
                popupsmg:i18next.t("Please enter message details"),
                imageType:'error'
            })  
        }
        else{
            let PurchaseRequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                authToken,
                applicationTypeId,
                purchaseNumber,
                toEmail,
                message,
                issue,
                languageCode,
                sessionUId,
                fromEmail
            })
            }
            fetch(`${config.envUrl}VendingAppApi/sendFiscalARTranscationManualSupportEmail`, PurchaseRequestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result) => {
                if(result.success && result.code==5052){
                    // sessionStorage.setItem('isMailSent','true');
                    that.setState({
                        isLoading:false,
                        ShowPopUp:true,
                        popupsmg:i18next.t("The message has been sent successfully"),
                        imageType:'success'
                    })
                }
                else{
                    that.setState({
                        isLoading:false,
                        ShowPopUp:true,
                        popupsmg:result.code==5015?i18next.t("No Record found"):i18next.t("CommonError"),
                        imageType:'error'
                    })  
                }
            })
            .catch(err=>{
                console.log(err)
                that.setState({
                    isLoading:false
                })
            })
        }
    }
    render() {
        const style = {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex:'999'
        };
        return (
            <div className="">        
                <div className="container">
                    <div className="heading">
                        <div className="row">
                            <div className="col-1">
                                <Link 
                                    to=
                                    {
                                        this.props.location.state.fromPurchaseHistory == 'purchaseHistory'
                                        ? 
                                        {
                                            pathname: '/PurchaseHistory'
                                        }
                                        :
                                        this.props.location.state.fromPurchaseHistory == 'orderSummary'
                                            ?
                                            {
                                                pathname: '/OrderSummary/'+this.state.purchaseNo,
                                                state:this.props.location.state.fromPurchaseHistory == 'orderSummary'
                                                ? '' : 'fromHome'
                                            }
                                            :
                                            {
                                                pathname: '/support'
                                            }
                                    }
                                >
                                    <img src={left_angle} className="backImg" style={{height:'25px'}} />
                                </Link>                                
                            </div>
                            <div className='col-10'>
                                <p className={`title mb-0 text-center text-dark`}>{(config.applicationTypeId==17 || config.applicationTypeId==19)? i18next.t("Support"):i18next.t("Need help")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center" >
                        <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center">
                            <div className="col-12 mt-5 editProfile-page">
                                <p className='needHelpSubTitle'>{i18next.t("Send us an email by filling out the form below")}</p>
                                <form className='Editform' onSubmit={this.sendMail}>
                                    {
                                        this.state.showFromEmail &&
                                        <div className='form-group'>
                                            <label>{i18next.t("Email")}</label>
                                            <input type='email' name='fromEmail' className='form-control' onChange={this.handleTextChange} placeholder={i18next.t("EnterEmail")} />
                                            {
                                            this.state.isInvalidEmail &&              
                                            <label className="text-danger">{i18next.t("Please enter a valid email address")}</label>
                                            } 
                                        </div>
                                    }
                                    <div className='form-group'>
                                        <label>{i18next.t("Select your issue")}</label>
                                        <select name='issue' className='form-control' defaultValue="" onChange={this.handleTextChange}>
                                            <option value=""></option>
                                            {
                                                IssueOptions.map((item,key)=>{
                                                    return <option value={i18next.t(item)} key={key}>{i18next.t(item)}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label htmlFor="description">{config.isLatamCountry ? i18next.t("Your message (2000 words)") : i18next.t("Your message (500 words)")}</label>
                                        <textarea id='description' name='description' rows={7} className='form-control border-1 mt-1' onChange={this.handleTextChange} maxLength={500} value={this.state.description}></textarea>
                                    </div>      
                                    <div className="text-center mt-5">
                                        <Button size="large" btnType="submit" text={i18next.t('NeedHelpButton')} className="btn mt-2 btn-theme-dark" disabled={this.state.isBtnDisable} />
                                    </div>
                                </form>
                            </div>
                        
                        </div>
                    </div> 
                    {
                        this.state.isLoading &&
                        <div className="" style={{height:'400px'}}>
                            <div className="spinner">
                                <div style={style}>
                                    <ClipLoader
                                        size={50}
                                        color={"#000000"}
                                        loading={this.state.isLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {this.state.ShowPopUp ? (
                        this.state.imageType=='error'?
                        <CoolerPopup
                            openPopup={this.state.ShowPopUp}
                            imagetype={this.state.imageType}
                            closePopup={this.closingShowPopUp}
                            message={this.state.popupsmg}
                        />
                        :
                        <CoolerPopup
                            openPopup={this.state.ShowPopUp}
                            imagetype={this.state.imageType}
                            title={i18next.t("Email successfully sent!")}
                            description={i18next.t("Your concern has been successfully communicated to the concern team.")}
                            btnText={i18next.t("GREAT!")}
                            clickHandle={this.closingShowPopUp}
                            fullWithButton={true}                      
                        />
                    ) : null}
                </div>
            </div>
        )
    }
}
