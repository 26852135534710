import React, { Component } from 'react'
import i18n from "i18next";
import { Trans } from "react-i18next";
import trash from '../_assets/images/icons/delete.png'
import { Modal, Button as Btn, Skeleton  } from 'antd';
import { cardService } from '../_services/card.service';
import { utilService } from "../_services/util.service";
import { config } from '../_config';
import { history } from '../_helpers';
import { ClipLoader } from 'react-spinners';
import CoolerPopup from '../components/Model-Popup/Popup';
import Heading from '../containers/Heading/Heading';
import i18next from 'i18next';
import ModelPopup from "../components/Model-Popup/Popup";
import ConfirmPopup from '../components/ConfirmPopup/ConfirmPopup';
import { Link } from 'react-router-dom';
import Button from '../components/button';
import FooterNav from '../components/FooterNav/FooterNav';
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData, encryptData } from '../_helpers/encryptData';
import IndiaPG from '../components/IndiaPG/IndiaPG';

export default class CardList extends Component {
    constructor(props){
        super(props);
        document.title=`Coke & Go ${config.countryName} | Card`;
        CapturePage({hitType:"pageview",page:"/Card"})    
        
        this.state = {
            isConfirmModalVisible:false,
            isLoading:true,
            isDisabled:false,
            cardList:[],
            cardID:'',
            popupmsg:'',
            showpopup:false,
            imagetype:'error',
            errorCode:0,
            isOffline:navigator.onLine?false:true,
            showTryAgain:false,
            ErrorCode:'',
            paymentFlow:'OLD',
            paymentGateway:'',
            transactionGoingOn:false
        }
        const successFlag = this.props.location.state ? this.props.location.state.successFlag : false;
        let that = this;
        cardService.getCardList().then(function(result){         
            if(result.success){
                that.setState({
                    cardList : result.cardList,
                    isLoading:false,
                    paymentFlow:result.paymentFlow,
                    paymentGateway:result.paymentGateway.toLowerCase(),
                    transactionGoingOn:result.transactionGoingOn
                })
                // if(result.cardList.length==0){localStorage.setItem('cardlist','true')}
                sessionStorage.setItem('paymentGateway',result.paymentGateway.toLowerCase())
                sessionStorage.setItem('paymentFlow',result.paymentFlow)
                if(result.paymentGateway.toLowerCase()=='elavon' && successFlag){
                    that.setState({
                        imagetype:'success',
                        showpopup:true,
                        popupmsg:result.isFBFRedeemedOnSameCard ? <Trans i18nKey={'FBFRedeemedOnSameCard'} components={[<div style={{marginTop: '15px', color: '#FF0000'}}></div>]}></Trans>:i18next.t('CardSuccess'),
                        isLoading:false
                    })
                }
            }
            else{
                if(result.code==401){
                    history.push("/logout")
                    that.setState({
                        isLoading:false,
                        errorCode:result.code,
                        imagetype:'error',
                        showpopup:true,
                        popupmsg:result.message
                    })
                }
                else{
                    that.setState({
                        isLoading:false,
                    })
                }
            }
        })  
        window.addEventListener("pageshow", function(evt){
            // console.log(evt.persisted)
            if(evt.persisted){
                setTimeout(function(){
                    window.location.reload();
                },10);
            }
        }, false);
        this.openConfirmModal = this.openConfirmModal.bind(this);       
        this.CloseModal = this.CloseModal.bind(this);       
        this.Addcard = this.Addcard.bind(this);       
        this.deleteCard = this.deleteCard.bind(this);
        this.closingPopup = this.closingPopup.bind(this);
        this.setAsDefault = this.setAsDefault.bind(this);
        this.closingisOfflinePopup = this.closingisOfflinePopup.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleEventClick = this.handleEventClick.bind(this);
        this.getUpdatedOffer = this.getUpdatedOffer.bind(this);
        this.sendMetricsTracking();
    }
    componentDidMount () {        
        if(config.applicationTypeId==31){
            const script = document.createElement("script");
            script.src = config.elevonSDK;
            script.async = true;
            document.body.appendChild(script);
        }
    }

    sendMetricsTracking = () => {
      utilService.metricsTracking("CardListingPage");
    }

    openConfirmModal(event){
        CaptureEvent({
            category:"delete card",
            action:"button clicked",
            label:"deleteCard"
          })
        event.persist()
        this.setState({
            isConfirmModalVisible:true,
            cardID:event.target.id
        })
    }
    CloseModal(){
        this.setState({
            isConfirmModalVisible:false
        })
    }
    handleCancelClick(){
        this.setState({
            showTryAgain:false
        })
    }
    handleEventClick(){
        this.setState({
            showTryAgain:false
        })
        this.Addcard()
    }
    closingPopup(){
        if(this.state.errorCode==401){
            history.push('/Logout');
        }
        else{
            this.setState({
                showpopup:false
            })
        }
        
    }
    getUpdatedOffer(){
        let user = JSON.parse(decryptData(localStorage.getItem("user")));
        let authToken = user.authToken;
        const applicationTypeId = config.applicationTypeId;
        let macAddress = decryptData(localStorage.getItem('qrcode'));
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: new URLSearchParams({
            authToken,
            applicationTypeId,
            macAddress
          })
        };
        fetch(
          `${config.envUrl}VendingAppApi/getConsumerOfferDetails`,
          requestOptions
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (body) {
            localStorage.setItem('reward', encryptData(JSON.stringify(body)))
          })
    }
    Addcard(){
        CaptureEvent({
            category:"add card",
            action:"button clicked",
            label:"addCard"
          })
        let that = this;
        let defaultPG = this.state.paymentGateway;
        let user = JSON.parse(decryptData(localStorage.getItem("user")));
        let qrcode = decryptData(localStorage.getItem("qrcode"));
        let authToken = user.authToken;
        // for ceilo
        if(config.applicationTypeId==19 && defaultPG=="payu" && this.state.paymentFlow=="NEW"){
            window.location.href=`${config.envUrl}VendingAppApi/requestForPayuConsent?authToken=${authToken}&deviceId=${qrcode}&returnURL=${window.location.origin+"/payment-status"}`
        }
        else if(config.paymentGateway==1 || config.paymentGateway==3 || config.paymentGateway==6 || config.paymentGateway==9 || (config.applicationTypeId==19 && defaultPG=="payu" && that.state.paymentFlow=="OLD") || config.paymentGateway==8){
            sessionStorage.removeItem('scanbutton');
            if(config.paymentGateway==8){
                window.location.href = window.location.origin+'/AddCard';
            }
            else{
                history.push("/AddCard");
            }
        }
        else if(config.paymentGateway==4 && defaultPG=='paytm'){
            
            if(this.state.paymentFlow=='CARD'){
                history.push('/AddCard')
            }
            else{                
                history.push('/link-with-paytm')
            }
        }
        else if(config.paymentGateway==5){//Global Pay
            that.setState({isLoading:true})  
            const applicationTypeId = config.applicationTypeId;
            let sessionUId = sessionStorage.getItem("sessionId");
            const redirectURL = config.envUrl+"VendingAppApi/getResponsefromHK"; //https://portal-qa.instagng.com/VendingAppApi/getResponsefromHK
            const returnURL = window.location.origin+"/payment-status"            
            let RequestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({authToken,applicationTypeId, sessionUId, redirectURL,returnURL})
            }
            fetch(`${config.envUrl}VendingAppApi/getRequestParametersForHK`, RequestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result) => {
                if(result.success)
                {
                    sessionStorage.setItem('returnData',JSON.stringify(result.returnData));
                    history.push("/AddCard");
                }
                else{
                    console.log(result)
                }
            });
        }
        else{
            that.setState({isLoading:true})            
            let user = JSON.parse(decryptData(localStorage.getItem("user")));
            let authToken = user.authToken;
            const applicationTypeId = config.applicationTypeId;
            const returnSuccessUrl = config.baseURL+'/AddCard';
            const returnFailUrl = config.baseURL+'/CardFail';
            let sessionUId = sessionStorage.getItem('sessionId');
            let RequestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({authToken,applicationTypeId, returnSuccessUrl,returnFailUrl,sessionUId})
            }
        
            fetch(`${config.envUrl}VendingAppApi/addPayment`, RequestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result) => {
                if(result.success && result.returnCode==5041){
                    sessionStorage.setItem('token',result.token);
                    window.location = result.redirectURL;
                }
                else{
                    if(result.code==401){
                        that.setState({
                            isLoading:false,
                            errorCode:result.code,
                            imagetype:'error',
                            showpopup:true,
                            popupmsg:result.message
                        })
                    }
                    else{
                        that.setState({
                            isLoading:false,
                            errorCode:result.code,
                            imagetype:'error',
                            showpopup:true,
                            popupmsg:result.message
                        })
                    }
                }
            }).catch((error) =>{
                that.setState({
                    isLoading:false,
                    imagetype:'error',
                    showpopup:true,
                    popupmsg:i18n.t('CommonError')
                })
            });
        }
    }
    setAsDefault(e){
        let that = this;
        that.setState({
            isLoading:true
        })
        let user = JSON.parse(decryptData(localStorage.getItem('user')));
        let request = {cardID:e.target.id,authToken:user.authToken};
        if(navigator.onLine){
            cardService.setDefaultCard(request).then(function(result){         
                if(result.success){
                    cardService.getCardList().then(function(result){         
                        if(result.success){
                            that.setState({
                                cardList : result.cardList
                            })
                        }
                    })  
                    that.setState({
                        imagetype:'success',
                        showpopup:true,
                        popupmsg: i18next.t("CardSetDefault"),
                        isLoading:false                    
                    })
                }
                else{
                    that.setState({
                        imagetype:'error',
                        showpopup:true,
                        popupmsg: i18next.t("CommonError"),
                        isLoading:false
                    })
                }
            })  
        }
        else{
            that.setState({isOffline:true,isLoading:false})
        }
    }
    async deleteCard(){
        let that =this
        that.setState({
            isConfirmModalVisible:false,
            isLoading:true
        })
        let data = that.state.cardList.filter((item)=>item.cardID==this.state.cardID)

        cardService.removeCard(data?data[0]:{}).then(function(result){ 
            if(result.success){
                that.setState({
                    imagetype:'success',
                    showpopup:true,
                    popupmsg:i18n.t("cardRemoved"),
                    isLoading:false
                    
                })              
                that.getUpdatedOffer()           
                cardService.getCardList().then(function(result){         
                    if(result.success){
                        that.setState({
                            cardList : result.cardList                           
                        })
                        // if(result.cardList.length==0){localStorage.setItem('cardlist','true')}
                    }
                })
            }
            else{
                that.setState({
                    imagetype:'error',
                    showpopup:true,
                    popupmsg:result.message ? result.code == 5015 ? i18n.t('cardNotFound'): result.message:i18n.t('ConnectionIssue'),
                    isLoading:false                    
                })
            }  
        })        
    }
    closingisOfflinePopup(){
        this.setState({
          isOffline:false
        })
    }

    render() {
        const style = {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex:'999'
          };
          let {paymentGateway:defaultPG,paymentFlow} = this.state;
          let qrcode = decryptData(localStorage.getItem('qrcode'))
          let paytmlist = this.state.cardList.filter((item)=>item.paymentGateway?.toLowerCase()=='paytm')
        return (
            <div className="main-wrapper">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="content p-0">   
                                <Heading title={i18n.t("PaymentOption")} firstIcon={true} secondIcon={false} backLink="/ScanButton" LinkIcon="back"/>   

                                <div className="">
                                    {
                                        this.state.isLoading?
                                        <div className="" style={{height:'400px',background:'#fff'}}>
                                            <div className="spinner">
                                                <div style={style}>
                                                    <ClipLoader
                                                        size={50}
                                                        color={"#FF0000"}
                                                        loading={this.state.isLoading}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        config.applicationTypeId==19?
                                        <IndiaPG cardList={this.state.cardList} defaultPG={defaultPG} addCard={this.Addcard} openConfirmModal={this.state.transactionGoingOn ? {} : this.openConfirmModal} setAsDefault={this.setAsDefault}/>
                                        :
                                        <div className="bg-white mt-3 cardList" style={{height:window.innerHeight-220}} >
                                                {
                                                    (this.state.cardList.length>0)?
                                                    this.state.cardList.map((card,key)=>{
                                                        if(card.isDefault){
                                                        return (
                                                            <div key={key}>
                                                                <div className="custom-card">
                                                                    <h5>{(config.paymentGateway==4 && defaultPG=='paytm') ? `+91 ${card.phoneNo.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}`:`XXXX XXXX XXXX ${card.lastDigits}`}</h5>
                                                                    <div className="py-3 pl-2 mt-1">
                                                                    <span>{ (config.paymentGateway==4 && defaultPG=='paytm')? "PayTM":card.cardType}</span>
                                                                        
                                                                    </div>
                                                                </div>
                                                                {
                                                                    <div className="text-md-right text-right action-icon">
                                                                    {/* <b>{i18next.t('defaultCard')}</b>&nbsp; */}
                                                                    <img src={trash} id={card.cardID} onClick={this.state.transactionGoingOn ? {} : this.openConfirmModal}/>
                                                                    </div>
                                                                }
                                                                  
                                                            </div>
                                                        )
                                                        }
                                                    }):''
                                                }
                                                {
                                                    (this.state.cardList.length>0)?
                                                    this.state.cardList.map((card,key)=>{
                                                        if(!card.isDefault){
                                                        return (
                                                                <div className="mb-3" key={key}>
                                                                    <div className="custom-card" style={{backgroundColor:'#555'}}>
                                                                        <h5>XXXX XXXX XXXX {card.lastDigits}</h5>
                                                                        <div className="py-3 pl-2 mt-1">
                                                                            <span>{card.cardType}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                            <div className='col-2'>
                                                                                <div className="form-check form-switch bx-pull-right ">
                                                                                    <input className="form-check-input on_off" type="checkbox" id={card.cardID} name='raido' onChange={this.setAsDefault}  checked={card.isDefault} />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-8'>
                                                                                <label className=" text-left" htmlFor={card.cardID} style={{cursor:'pointer'}}>{i18next.t('SetDefaultCard')}</label>
                                                                            </div>
                                                                            <div className='col-2'>
                                                                                <img src={trash} id={card.cardID} onClick={this.state.transactionGoingOn ? {} : this.openConfirmModal} className="" height={20}/>
                                                                            </div>
                                                                    </div> 
                                                                </div>
                                                        ) 
                                                        }
                                                    })
                                                    : 
                                                    <div className="emptycard">                                                        
                                                        <h4 className="text-center">{i18next.t("CardEmpty")}</h4>
                                                        {/* removed paytm wallet message */}
                                                        {/* config.paymentGateway==4 && defaultPG=='paytm')?i18next.t("Payment method is not added. Please link your Paytm walltet") */}
                                                    </div>
                                                }
                                        </div>
                                    }
                                    <div className="add-card-div mt-3">
                                        {
                                            (config.paymentGateway!=4 || (config.applicationTypeId==19 && qrcode && ((defaultPG=='paytm' && paymentFlow=='WALLET' && paytmlist.length==0)||paymentFlow=='CARD'||defaultPG=='payu'))) ?
                                        
                                            <Button className="btn btn-theme-dark" size="large" btnType="button" clickHandler={this.Addcard} text={i18next.t(((defaultPG=='paytm' && paymentFlow=='WALLET')) ?'Link to Paytm Wallet':'AddCard')} />
                                            :
                                            ""
                                        }
                                    </div>
                                    <FooterNav />
                                    {
                                        this.state.showpopup ? (
                                        <CoolerPopup
                                            imagetype={this.state.imagetype}
                                            color="Red"
                                            openPopup={this.state.showpopup}
                                            closePopup={this.closingPopup}
                                            message={this.state.popupmsg}
                                        />) : null }
                                        {this.state.isOffline ? (
                                            <ModelPopup
                                            openPopup={this.state.isOffline}
                                            closePopup={this.closingisOfflinePopup}
                                            message={i18next.t("ConnectionIssue")}
                                            imagetype={"error"}
                                            />
                                        ) : null}
                                        {
                                            this.state.showTryAgain ? (
                                            <ConfirmPopup
                                                imagetype="Error"
                                                color="Red"
                                                openPopup={this.state.showTryAgain}
                                                message={this.state.popupmsg}
                                                CancelText="Cancel"
                                                EventText="Try again"
                                                handleEventClick={this.handleEventClick}
                                                handleCancelClick={this.handleCancelClick}
                                            />
                                            )
                                            : null
                                        }
                                        {
                                            this.state.isConfirmModalVisible ? (
                                                <ConfirmPopup
                                                    openPopup={this.state.isConfirmModalVisible}
                                                    message={i18next.t('CardDeleteConfirmMsg')}
                                                    CancelText={i18next.t('No')}
                                                    EventText={i18next.t('Yes')}
                                                    handleEventClick={this.deleteCard}
                                                    handleCancelClick={this.CloseModal}
                                                />)
                                            : null 
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        )
    }
}
