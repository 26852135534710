import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Backbutton from "../../assets/images/LogIn/arrow-back.svg";

const BackData = ({ title, paragraphLine1, paragraphLine2, BackLink }) => {
  var qrcodechecklink = localStorage.getItem("qrcode") ? "Home" : "Scanbutton";
  return (
    <div className="row ">
      <div className="col-3">
        <div className="float-left">
          <Link to={process.env.PUBLIC_URL + "/" + qrcodechecklink}>
            <img src={Backbutton} alt="Back" />
          </Link>
        </div>
      </div>
      <div className="col-9">
        <div className="text-right back-text clrWhite">
          <h4 style={{color:"#fff"}}>{title}</h4>
          <p className="px-1">
            {paragraphLine1}
            <br />
            {paragraphLine2}
          </p>
        </div>
      </div>
    </div>
  );
};

BackData.propTypes = {
  BackLink: PropTypes.string,
  title: PropTypes.string,
  paragraphLine1: PropTypes.string,
  paragraphLine2: PropTypes.string
};

export default BackData;
