import React, { Component } from 'react'
import { config,PaymentGatewayList } from '../_config';
import Heading from '../containers/Heading/Heading';
import { ClipLoader } from 'react-spinners';
import i18next from 'i18next';
import { Trans } from "react-i18next";
import ModelPopup from "../components/Model-Popup/Popup";
import CoolerPopup from '../components/Model-Popup/Popup';
import { history } from '../_helpers';
import ReactInputMask from "react-input-mask";
import logo from "../_assets/images/icons/hor-logo.png";
import close from "../_assets/images/icons/close.png";
import { Link } from 'react-router-dom';
import creditCardType  from "credit-card-type";
import FooterNav from '../components/FooterNav/FooterNav';
import CardScan from "../_assets/images/icons/CardScan.png";
import CardScanner from './CardScanner';
import { formatCreditCardNumber } from '../_helpers/cardUtil';
import i18n from "i18next";
import GlobalPayForm from '../components/GlobalPayForm/GlobalPayForm';
import { getAppId } from "../_helpers/getAppId";
import { CaptureEvent, CapturePage } from "../_helpers/googleAnalytics";
import { decryptData } from '../_helpers/encryptData';
import AuresysPayForm from '../components/AuresysPayForm/AuresysPayForm';
import { utilService } from "../_services/util.service";
import { cardService } from '../_services/card.service';
import ConfirmPopup from '../components/ConfirmPopup/ConfirmPopup';

const ApplicationTypeId = getAppId();
var lookup = require('binlookup')();

export default class AddCard extends Component {
    constructor(props){
        super(props);   
        document.title=`Coke & Go ${config.countryName} | AddCard`;
        CapturePage({hitType:"pageview",page:"/AddCard"})    
        
        this.state = {
            isLoading:config.applicationTypeId==31?true:false,
            popupmsg:'',
            showpopup:false,
            imagetype:'error',
            errorCode:0,
            isOffline:navigator.onLine?false:true,
            checkRedirection:props.location.state,
            card:{
                CustomerName:'',
                CardNumber:'',
                Holder:'',
                ExpirationDate:config.paymentGateway==5?'__-20__':'__/20__',  //04/2021
                Brand:'',
                CVV:'',
                globalpay_card_type:'',
                customerConsent:0,
                isConsentChecked:false
            },
            cardNumberFormat:'',
            isOpenScanCard:false,
            cvvMask:"999",
            debitCardWarning: false,
            saveDisabled: false,
            showTryAgain:false
        }

        this.addCard = this.addCard.bind(this);
        this.closingisOfflinePopup = this.closingisOfflinePopup.bind(this);
        this.closingPopup = this.closingPopup.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.MonthPicker = this.MonthPicker.bind(this);
        this.sendMetricsTracking();
        
        if(config.paymentGateway==2){
            let walletToken = sessionStorage.getItem('saferWalletToken');
            let cardToken = sessionStorage.getItem('token');
            if(config.isSaferPayWalletEnabled && walletToken){
                this.verifyWalletAuth()
            }
            else if(cardToken!=""){
                this.addCard()
            }
            else{
                history.push(walletToken!=""?"/Home":"/Card")
            }
        }
    }
    
    sendMetricsTracking = () => {
        utilService.metricsTracking("AddCardPage");
    }

    componentDidMount () {    
        if(config.applicationTypeId==20){
            const script = document.createElement("script");
            script.src = config.auresyssdk;
            script.async = true;
            document.body.appendChild(script); 
        }  
        if(config.applicationTypeId==31){
            // const script = document.createElement("script");
            // script.src = config.elevonSDK;
            // script.async = true;
            // document.body.appendChild(script);
            this.AddElevonPayCard()
        } 
        // var ele = document.querySelector(".ant-picker-input input");
        // ele.disabled = true
    }

    closingisOfflinePopup(){
        this.setState({
          isOffline:false
        })
    }

    closingPopup(){
        if(this.state.errorCode==401){
            history.push('/Logout');
        }
        else{
            if(this.state.imagetype=='success'){
                const qrcode = decryptData(localStorage.getItem('qrcode'));
                if(this.state.checkRedirection){                    
                    history.push(`${process.env.PUBLIC_URL + "/PayOutstandingbalance/"+this.state.checkRedirection}`)
                }
                else{
                    if(qrcode){
                        history.push('/Home')
                    }
                    else{
                        let fromdata = sessionStorage.getItem('scanbutton');
                        let newuser = sessionStorage.getItem('newuser');
                        if(fromdata=='true' || newuser=='true'){
                            sessionStorage.removeItem('scanbutton');
                            sessionStorage.removeItem('newuser');
                            history.push('/ScanButton')
                        }
                        else{
                            history.push('/Card')
                        }
                    }
                }
            }
            else{
                this.setState({
                    showpopup:false
                })
                if(config.applicationTypeId==31){
                    history.push('/Card')
                }
            }
            
        }        
    }
    handleChange(event){
        const { name, value } = event.target;
        if(name=="Brand"){
            const { card } = this.state;
            const re = /^[A-Za-z]+$/;
            if (value && re.test(value)){
                
                this.setState({
                    card:{
                        ...card,
                        [name]:value
                    }
                })
            }
            else if(value==''){
                this.setState({
                    card:{
                        ...card,
                        [name]:value
                    }
                })
            }
        }
        else if(name=="card_expiry_date"){
            const { card } = this.state;
            this.setState({
                card:{
                    ...card,
                    ExpirationDate:value
                }
            })
        }
        else{       
            const { card } = this.state;
            if(name=="CardNumber"){
                if (!(ApplicationTypeId === 13 || ApplicationTypeId === 14 || ApplicationTypeId === 15 || ApplicationTypeId === 17 || ApplicationTypeId == 20)) {
                    let cardNo = value.replaceAll(" ", "");
                    if (cardNo.length >= 6 && this.state.debitCardWarning == false) {
                        // lookup(cardNo).then(
                        //     data => {
                        //         if (data.type == 'debit') {
                        //             this.setState({
                        //                 debitCardWarning: true,
                        //                 saveDisabled: true
                        //             })
                        //         }
                        //     })
                    }
                    else if(cardNo.length < 6){
                        this.setState({
                            debitCardWarning: false,
                            saveDisabled: false
                        })
                    }

                }
                let format = formatCreditCardNumber(value);
                let brand = creditCardType(value);
                let brandName = brand.length>0 && brand[0].niceType ?  brand[0].niceType: '';
                this.setState({
                    card:{
                        ...card,
                        CardNumber:format,
                        Brand:brandName,
                        [name]:format.replace(/ /g,'')
                    },
                    cardNumberFormat:format,
                    cvvMask:brandName=="American Express"?"9999":"999"
                })
            } 
            else if(name=='card_number' && (config.applicationTypeId==21 || config.applicationTypeId==23)){
                let format = formatCreditCardNumber(value);
                let brand = creditCardType(value);
                let brandName = brand.length>0 && brand[0].niceType ?  brand[0].niceType: '';
                const check_card_type = {"001":"Visa","002":"Mastercard","003":"American Express","004":"Discover","005":"Diners Club","006":"Carte Blanche","007":"JCB","014":"EnRoute"}
                var card_type = Object.keys(check_card_type).filter((index)=>{return check_card_type[index]===brandName})
                sessionStorage.setItem('card_number',value)
                this.setState({
                    card:{
                        ...card,
                        CardNumber:value,
                        Brand:brandName,
                        globalpay_card_type:card_type.length>0?card_type[0]:''
                    },
                    cvvMask:brandName=="American Express"?"9999":"999"
                })
            }  
            else if(name=='card_cvn'){
                this.setState({
                    card:{
                        ...card,
                        CVV:value,
                    },
                })
            }
            else{
                this.setState({
                    card:{
                        ...card,
                        [name]:value
                    }
                })
            }
            
        }
    }

    MonthPicker(event){        
        let { card } = this.state;
        this.setState({
            card:{
                ...card,
                ['ExpirationDate']:event.target.value
            }
        })
    }

    addCard(event){
        CaptureEvent({
            category:"save card",
            action:"button clicked",
            label:"saveCard"
          })
        this.setState({isLoading:true})
        let paymentGateway = sessionStorage.getItem('paymentGateway');
        if(config.paymentGateway==1 || config.paymentGateway==3 || config.paymentGateway==6 || paymentGateway=='PayU' || paymentGateway=='paytm' || config.paymentGateway==9){
            event.preventDefault();
            let { card } = this.state;
            let user = JSON.parse(decryptData(localStorage.getItem('user')));
            let that = this;
            if(card.CardNumber && card.Brand && card.Holder && card.ExpirationDate && (paymentGateway=='cielo' || paymentGateway=='auresys' || (paymentGateway=='paytm' && card.customerConsent && card.CVV) || (paymentGateway!=='paytm' && card.CVV))){
                const value = card.ExpirationDate.split('_').join('');
                const ExpDate = value.split('/')
                var date = new Date();
                const month = date.getMonth()+1;
                const year = date.getFullYear();
                const regExp = new RegExp("^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$")
                let res = regExp.test(card.CardNumber)
                if(!res){
                    that.setState({  
                        isLoading: false,              
                        showpopup: true,
                        imagetype:"error",
                        popupmsg: i18next.t("InvalidCard")
                    })
                }
                else if(ExpDate[1] == "" || parseInt(ExpDate[1])<year || ExpDate[0]==""){
                    that.setState({  
                        isLoading: false,              
                        showpopup: true,
                        imagetype:"error",
                        popupmsg: i18next.t("Please enter the correct expiration date")
                    })
                }
                else if(parseInt(ExpDate[0])>12 || (parseInt(ExpDate[0])<month && parseInt(ExpDate[1])===year)){
                    that.setState({     
                        isLoading: false,           
                        showpopup: true,
                        imagetype:"error",
                        popupmsg: i18next.t("Please enter the correct expiration date")
                    })
                }
                else{
                    if(config.paymentGateway==6){
                        this.AddCardForAuresys()
                    }
                    else{
                        const applicationTypeId = config.applicationTypeId;
                        const authToken = user.authToken;
                        const CustomerName = user.firstName+" "+user.lastName;
                        const CardNumber = card.CardNumber.split(" ").join("");
                        const Holder = card.Holder;
                        const Brand = card.Brand;
                        const CVV = card.CVV;
                        const ExpirationDate = card.ExpirationDate;
                        let qrcode=sessionStorage.getItem('qrcode')
                        const deviceId = qrcode?qrcode:decryptData(localStorage.getItem('qrcode'));
                        const desc = paymentGateway?paymentGateway:PaymentGatewayList[config.paymentGateway]
                        const currencyCode = config.currencyCode;
                        let sessionUId = sessionStorage.getItem('sessionId');
                        const customerConsent = card.customerConsent
                        fetch(config.envUrl+"VendingAppApi/addPayment", {
                            method: "POST",
                            body: new URLSearchParams({
                                applicationTypeId,
                                authToken,
                                CustomerName,
                                CardNumber,
                                Holder,
                                Brand,
                                ExpirationDate,
                                deviceId,
                                desc,
                                CVV,
                                currencyCode,
                                customerConsent,
                                sessionUId
                            }),
                            headers: { "Content-Type": "application/x-www-form-urlencoded" },
                        })
                        .then(function (response) {
                            return response.json();
                        })
                        .then(function(body){    
                            if(body.success && (body.code && body.code==5044)){
                                const postUrl = body.actionUrl;
                                const errorUrl = config.baseURL + '/payment-status';
                                const content = JSON.parse(body.content);
                                if(that.state.checkRedirection){
                                    sessionStorage.setItem('cardAddedFromPending',that.state.checkRedirection)
                                    sessionStorage.setItem("newCard",body.token)
                                }
                                const paytmUrl = config.envUrl+'paytm.aspx?MD='+content.MD+'&PaReq='+content.PaReq+'&TermUrl='+content.TermUrl+'&postURL='+postUrl+'&errorURL='+errorUrl
                                window.location = paytmUrl
                            }                  
                            else if(body.success && body.returnCode==5041){ 
                                if(that.state.checkRedirection){
                                    sessionStorage.setItem("newCard",body.token)
                                }
                                that.setState({   
                                    isLoading:false,             
                                    showpopup: true,
                                    imagetype:"success",
                                    popupmsg:body.isFBFRedeemedOnSameCard ? <Trans i18nKey={'FBFRedeemedOnSameCard'} components={[<div style={{marginTop: '15px', color: '#FF0000'}}></div>]}></Trans>:i18next.t('CardSuccess')
                                })
                                // localStorage.removeItem('cardlist');
                            }
                            else{
                                that.setState({       
                                    isLoading:false,             
                                    showpopup: true,
                                    imagetype:"error",
                                    popupmsg: i18next.t("InvalidCard")
                                })
                            }
                        })
                        .catch(function(err){
                            if(navigator.onLine){
                                that.setState({       
                                    isLoading:false,             
                                    showpopup: true,
                                    imagetype:"error",
                                    popupmsg: err?err:i18next.t("CommonError")
                                })
                            }
                            else{
                                that.setState({
                                    isLoading:false,   
                                    isOffline:true
                                })
                            }
                            
                        })
                    }
                }
            }
            else{
                that.setState({                
                    showpopup: true,
                    imagetype:"error"
                })
                if(!card.CardNumber.trim()){
                    that.setState({
                        popupmsg: i18next.t("CardNumber")
                    })
                }
                else if(!card.ExpirationDate.trim()){
                    that.setState({
                        popupmsg: i18next.t("ExpDate")
                    })
                }            
                else if(!card.Brand.trim()){
                    that.setState({
                        popupmsg: i18next.t("CardBrand")
                    })
                }
                else if(!card.Holder.trim()){
                    that.setState({
                        popupmsg: i18next.t("HolderName")
                    })
                }
                else if(!card.CVV && (paymentGateway=='dlocal' || paymentGateway=='payu' || paymentGateway=='m2m')){
                    that.setState({
                        popupmsg: i18next.t("EnterCVV")
                    })
                }
                else if(!card.isConsentChecked && paymentGateway=='paytm'){
                    that.setState({
                        popupmsg: i18next.t("It is mandatory to verify your card, Kindly select the verify card checkbox")
                    })
                }
                that.setState({isLoading:false})
            }
        }
        else if(config.paymentGateway==2){
            let user = JSON.parse(decryptData(localStorage.getItem("user")));
            let authToken = user.authToken;
            let token = sessionStorage.getItem('token');
            const applicationTypeId = config.applicationTypeId;
            let sessionUId = sessionStorage.getItem("sessionId");
            let RequestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({authToken,token,applicationTypeId,sessionUId})
            }
        
            fetch(`${config.envUrl}VendingAppApi/fetchAliasDetailsForCCH`, RequestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result) => {
                if(result.success && result.code==5022){
                    sessionStorage.removeItem('token');
                    this.setState({
                        imagetype:'success',
                        showpopup:true,
                        popupmsg:result.isFBFRedeemedOnSameCard ? <Trans i18nKey={'FBFRedeemedOnSameCard'} components={[<div style={{marginTop: '15px', color: '#FF0000'}}></div>]}></Trans>:i18next.t('CardSuccess'),
                        isLoading:false
                    })
                    
                }
                else{
                    this.setState({
                        imagetype:'error',
                        showpopup:true,
                        popupmsg:result.message,
                        errorCode:result.code,
                        isLoading:false
                    })
                }
            }).catch((error)=> {
                this.setState({
                    imagetype:'error',
                    showpopup:true,
                    popupmsg:error.message,
                    errorCode:error.code,
                    isLoading:false
                })
            });
        }
        else if(config.paymentGateway==5){
            let { card } = this.state;
            let that = this;  
            const value = card.ExpirationDate.split('_').join('');
            const ExpDate = value.split('-')
            var date = new Date();
            const month = date.getMonth()+1;
            const year = date.getFullYear();
            if(!card.CardNumber.trim()){
                event.preventDefault();
                that.setState({
                    isLoading: false,              
                    showpopup: true,
                    imagetype:"error",
                    popupmsg: i18next.t("CardNumber")
                })
            }
            else if((ExpDate[1] == "" || parseInt(ExpDate[1])<year || ExpDate[0]=="") || (parseInt(ExpDate[0])>12 || (parseInt(ExpDate[0])<month && parseInt(ExpDate[1])===year))){
                event.preventDefault();
                that.setState({  
                    isLoading: false,              
                    showpopup: true,
                    imagetype:"error",
                    popupmsg: i18next.t("Please enter the correct expiration date")
                })
            }                           
            else if(!card.Brand.trim()){
                // that.setState({
                //     isLoading: false,              
                //     showpopup: true,
                //     imagetype:"error",
                //     popupmsg: i18next.t("CardBrand")
                // })
            }
            else{

            }
            
        }
    }

    AddCardForAuresys=()=>{
        let { card } = this.state;
        try {
            window.My2c2p.getEncrypted("addCardForm", function (encryptedData, errCode, errDesc) {
                console.log(encryptedData)
            })
        } catch (error) {
            
        }
    }

    openCard = ()=>{
        this.setState({
            isOpenScanCard:true
        })
    }

    handleTakePhoto = (cardDetail)=>{        
        const { card } = this.state;
        let brandName = '';
        if(cardDetail?.cardNumber){
            let brand = creditCardType(cardDetail.cardNumber);
            brandName = brand.length>0 && brand[0].type ?  brand[0].type: '';
        }
        let format = formatCreditCardNumber(cardDetail?.cardNumber);
        this.setState({
            card:{
                ...card,
                CardNumber:(cardDetail?.cardNumber).replace(/\s+/g,''),
                Holder:cardDetail?.name,
                ExpirationDate:cardDetail?.validTo || '__/20__',
                CVV: '',
                Brand: brandName
            },
            isOpenScanCard:false,
            cardNumberFormat:format,
            cvvMask:brandName=="American Express"?"9999":"999"
        })
    }
    handleEventClick=()=>{
        this.setState({
            showTryAgain:false,
            isLoading:true
        })
        this.AddElevonPayCard()
    }
    handleCancelClick=()=>{
        this.setState({
            showTryAgain:false
        })
        if(this.state.checkRedirection){                    
            history.push(`${process.env.PUBLIC_URL + "/PayOutstandingbalance/"+this.state.checkRedirection}`)
        }
        else{
            history.push('/Card')
        }
    }

    AddElevonPayCard=async()=>{
        let that = this;
        let user = JSON.parse(decryptData(localStorage.getItem("user")));
        let request = {authToken:user.authToken};
        const result = await cardService.getCardToken(request).then(function(result){         
            return result;
        }) 
        if(result.success && result.returnCode==5041){
            const {token,jsURL,tokenizeURL}=result
            // const script = document.createElement("script");
            // script.src = jsURL;
            // script.async = true;
            // document.body.appendChild(script);
            
            let lightbox;

            const MessageTypes = window.ElavonLightbox.MessageTypes;
            // do work to create a sessionId
            const sessionId = token;
            if (!lightbox) {
                lightbox = new window.ElavonLightbox({
                sessionId: sessionId,
                onReady: (error) =>
                    error
                    ? console.error('Lightbox failed to load',error)
                    : lightbox.show(),
                messageHandler: (message, defaultAction) => {
                    switch (message.type) {
                    case 'close':
                        history.push('/Card')
                        break;
                    case MessageTypes.transactionCreated:
                        console.log(message.sessionId)
                        // submitData({
                        // sessionId: message.sessionId,
                        // });
                        break;
                    case MessageTypes.hostedCardCreated:
                        let req = {
                            transactionId:message.hostedCard,
                            authToken:user.authToken
                        }   
                        let successFlag = false   
                        cardService.addTransactionId(req).then(function(res){
                            console.log(res);
                            if(res.success==true){
                                successFlag = true;
                            }
                        })  

                        let returnURL = new URL(tokenizeURL);
                        let swipeURL = `${returnURL.origin}/elavon/eu/lightboxresult?sessionid=${message.sessionId}&hostedcard=${message.hostedCard}`

                        fetch(swipeURL,{method:'GET'})
                        .then(result => {
                            lightbox.destroy()
                            if(this.state.checkRedirection){                    
                                history.push(`${process.env.PUBLIC_URL + "/PayOutstandingbalance/"+this.state.checkRedirection}`)
                            }
                            else{
                                history.push('/card' , { successFlag: successFlag });
                            }  
                        }); 

                        
                        // submitData({
                        // convergePaymentToken: message.hostedCard,
                        // hostedCard: message.hostedCard,
                        // sessionId: message.sessionId,
                        // });
                        console.log(message,{
                            convergePaymentToken: message.hostedCard,
                            hostedCard: message.hostedCard,
                            sessionId: message.sessionId,
                            })
                        break;
                    }
                    defaultAction();
                },
                });
            } else {
                lightbox.show();
            }
        }
    }
    render() {

        if(this.state.isOpenScanCard){
            return(
                <CardScanner handleTakePhoto={this.handleTakePhoto}/>
            )
        }

        const style = {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex:'999'
          };
        let paymentGateway = sessionStorage.getItem('paymentGateway')
        const newuser = sessionStorage.getItem('newuser')
        const backlink = newuser=="true"?"/welcome":this.state.checkRedirection?`${process.env.PUBLIC_URL + "/PayOutstandingbalance/"+this.state.checkRedirection}`:'/Card';
        let headerText = config.enableCardScan ? i18next.t("CardScannerTitle") : i18next.t("AddPayment");
        return (
            <div className="h-100" style={{backgroundColor:"#ccc"}}>        
                <div className="container bg-white">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6  d-flex flex-column  align-items-center justify-content-center"> 
                            <div className="col-12">   
                                <Heading title= {headerText} firstIcon={true} secondIcon={false} backLink={backlink} LinkIcon="back"/>               
                            </div>
                        </div>
                    </div>
                </div>                              
                <div className='card-form'>
                    {
                        config.enableCardScan &&
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={CardScan} className="mb-3" onClick={this.openCard} />
                        </div>
                    } 
                    {
                        (config.paymentGateway==1 || config.paymentGateway==3 || paymentGateway=='PayU' || paymentGateway=='paytm') && (
                            
                        <form onSubmit={this.addCard} >                            
                            <div className='addCard' >
                                <div className="row">
                                        {config.enableCardScan &&
                                            <div className='col-md-12'>
                                                <div className='text-center'>
                                                    <label className="font-weight-600">{i18next.t('EnterCardDetails')}</label>
                                                </div>
                                            </div>
                                        }
                                    <div className='col-md-12'>
                                        <div className='text-center'>
                                            <img src={logo} height={50} className="my-2" />
                                            <Link to="/Card" className="float-right" ><img src={close} /></Link>
                                        </div>
                                    </div>
                                    <div className=" col-12 py-2">
                                        <label className="font-weight-600">{i18next.t('CardNumberLabel')}</label>
                                        <input id="CardNumber" className="form-control addCardInput" name="CardNumber" onChange={this.handleChange} placeholder="XXXX XXXX XXXX XXXX" value={this.state.cardNumberFormat} />
                                        {
                                            this.state.debitCardWarning &&              
                                            <label className="text-danger">{i18n.t("Debit Card Warning")}</label>
                                        }
                                        {/* <ReactInputMask name="CardNumber" value = {this.state.card.CardNumber} onChange={this.handleChange} className="form-control " mask="9999 9999 9999 9999" maskChar="_" placeholder="XXXX XXXX XXXX XXXX"/> */}
                                    </div>   
                                    <div className=" col-12 py-2">
                                        <label className="font-weight-600">{i18next.t('CardHolderLabel')}</label>
                                        <input id="Holder" className="form-control addCardInput"  name="Holder" value={this.state.card.Holder} placeholder={i18next.t('CardHolderLabel')} onChange={this.handleChange} />
                                    </div>   
                                    <div className='col-12'>                                        
                                    { 
                                        (config.paymentGateway==3 || paymentGateway=='dlocal' || paymentGateway=='PayU' || paymentGateway.toLocaleLowerCase()=='paytm') ?
                                        <div className='row'>
                                            <div className="col-8 py-2 pl-3">
                                                <label className="font-weight-600">{i18next.t('CardExpiryLabel')}</label>
                                                {/* <DatePicker picker="month" name="ExpirationDate" className="form-control" onChange={this.MonthPicker} disabledDate={(current)=>{return current && current < moment(new Date)}} format="MM/YYYY" placeholder={config.applicationTypeId==9?'MM/AAAA':'MM/YYYY'} allowClear={false} placement="topleft"/> */}
                                                <ReactInputMask name="ExpirationDate" value = {this.state.card.ExpirationDate} onChange={this.MonthPicker} className="form-control addCardInput" id='ExpirationDate' mask="99/2099" placeholder={config.expiryDatePlaceHolder} />
                                            </div>
                                            <div className="col-4 py-2 pr-3">
                                                <label className="font-weight-600">{i18next.t('CVV')}</label>
                                                <ReactInputMask name="CVV" value = {this.state.card.CVV} onChange={this.handleChange} className="form-control addCardInput" mask={this.state.cvvMask} placeholder={this.state.cvvMask=="999"?"XXX":"XXXX"}/>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <label className="font-weight-600">{i18next.t('CardExpiryLabel')}</label>
                                            {/* <DatePicker picker="month" name="ExpirationDate" className="form-control" onChange={this.MonthPicker} disabledDate={(current)=>{return current && current < moment(new Date)}} format="MM/YYYY" placeholder={config.applicationTypeId==9?'MM/AAAA':'MM/YYYY'} placement="topleft" allowClear={false} /> */}
                                            <ReactInputMask name="ExpirationDate" value = {this.state.card.ExpirationDate} onChange={this.MonthPicker} className="form-control addCardInput" mask="99/2099" placeholder={config.applicationTypeId==9?'MM/AAAA':'MM/YYYY'} id='ExpirationDate'/>
                                        </>
                                    }
                                    </div>                                      
                                    
                                    <div className=" col-12 py-2">
                                        <label className="font-weight-600">{i18next.t('CardBrandLabel')}</label>
                                        <input id="Brand" type="text" className="form-control addCardInput" name="Brand" placeholder="Visa" value={this.state.card.Brand} onChange={this.handleChange} readOnly />
                                    </div>
                                    {  
                                        (config.applicationTypeId==19 && paymentGateway=='paytm')
                                        &&
                                        <div className='col-12 py-2'>                                            
                                            <div style={{display:'flex',justifyContent:'space-between',verticalAlign:'middle'}}>
                                                <label className="font-weight-600" htmlFor='customerConsent' style={{paddingLeft:'6px',marginTop:'5px'}}>{i18next.t('Consent to Verify the card')}</label>
                                                <input id="customerConsent" type="checkbox" name='customerConsent' checked={this.state.card.isConsentChecked} value={1} onClick={(e)=>{
                                                    let input = e.target
                                                    this.setState(prevState=>({
                                                        ...prevState,
                                                        card:{
                                                            ...prevState.card,
                                                            customerConsent:input.checked?1:0,
                                                            isConsentChecked:input.checked
                                                        }
                                                    }))
                                                }}/>
                                            </div>
                                        </div>
                                    }
                                    <div className="col-12 mt-3">
                                        <button type="submit" className="btn w-100 btn-addcard" disabled={this.state.saveDisabled}>{i18next.t('Save')}</button>
                                    </div> 
                                </div>  
                            </div>                                                             
                        </form>
                        
                        )
                    }
                    {
                        config.paymentGateway==6 &&
                        <AuresysPayForm card={this.state.card}/>
                    }
                    {
                        config.paymentGateway==5 &&
                        <GlobalPayForm logo={logo} close={close} checkRedirection={this.state.checkRedirection} 
                            onsubmit={this.addCard}
                            cardDetail={this.state.card}
                            handleChange={this.handleChange}
                            cvvMask={this.state.cvvMask}
                        />
                    }
                    {
                        ApplicationTypeId == 20
                        &&
                        <p className='addCard-Note'>Note: $1 Authorization amount is for validation purposes, this amount will be auto-reversed and you will not be charged.</p>
                    }
                    <FooterNav />
                    {
                        this.state.isLoading?
                        <div className="" style={{height:'400px'}}>
                            <div className="spinner">
                                <div style={style}>
                                    <ClipLoader
                                        size={50}
                                        color={"#FF0000"}
                                        loading={this.state.isLoading}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                        }

                    { 
                        this.state.showpopup ? (
                        <CoolerPopup
                            imagetype={this.state.imagetype}
                            color="Red"
                            openPopup={this.state.showpopup}
                            closePopup={this.closingPopup}
                            message={this.state.popupmsg}
                        />) : null 
                    }
                    {
                        this.state.isOffline ? (
                        <ModelPopup
                            openPopup={this.state.isOffline}
                            closePopup={this.closingisOfflinePopup}
                            message={i18next.t("ConnectionIssue")}
                            imagetype={"error"}
                        />
                        ) : null
                    }
                    {
                        this.state.showTryAgain ? (
                        <ConfirmPopup
                            imagetype="Error"
                            color="Red"
                            openPopup={this.state.showTryAgain}
                            message={this.state.popupmsg}
                            CancelText="Cancel"
                            EventText="Try again"
                            handleEventClick={this.handleEventClick}
                            handleCancelClick={this.handleCancelClick}
                        />
                        )
                        : null
                    }
                </div>            
            </div>
            
        )
    }
}